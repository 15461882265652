import Vue from 'vue';

export default {
    recuperarTiposRelatoriosDisponiveis(processoOid) {
        return Vue.axios.get('materialAplicacao/tiposRelatorio/' + processoOid);
    },

    gerarRelatorioCSV(relatorioRequest) {
        return Vue.axios.post('materialAplicacao/relatorio/csv', relatorioRequest, {
            responseType: "arraybuffer",
            headers: {
                'Accept': "text/csv"
            }
        });
    },

    gerarRelatorioPDF(relatorioRequest) {
        console.log(relatorioRequest)
        return Vue.axios.post('materialAplicacao/relatorio/pdf', relatorioRequest, {
            responseType: "arraybuffer",
            headers: {
                'Accept': "application/pdf"
            }
        });
    },

}