<template>
  <v-card>
    <v-card-title class="justify-center mb-3 titulo">
      <h2>
        Material de Aplicação
      </h2>
    </v-card-title>
    <v-card-text v-if="loaded">
      <v-row>
        <v-col cols="6">
          <v-autocomplete
              v-model="processoSelecionado"
              :items="processos"
              outlined
              label="Selecione um processo"
              placeholder="Selecione um processo"
              item-text="nome"
              item-value="oid"
              return-object
          />
        </v-col>
        <v-col cols="6" :key="processoSelecionado && processoSelecionado.oid">
          <v-select
              v-model="tipoRelatorioSelecionado"
              :items="tiposRelatorioDisponiveis"
              label="Selecione um relatório"
              placeholder="Selecione um relatório"
              item-text="nomeRelatorio"
              item-value="nomeArquivo"
              outlined
              :disabled="!processoSelecionado"
              return-object
          />
          <v-select
              v-if="tipoRelatorioSelecionado && tipoRelatorioSelecionado.geravelEmBranco"
              v-model="emBranco"
              :items="simOuNao"
              label="Gerar relatório em branco"
              placeholder="Selecione uma opção"
              item-text="text"
              item-value="value"
              outlined
              :disabled="!processoSelecionado && !tipoRelatorioSelecionado"
              style="width: 35%;"
          />
          <div v-if="tipoRelatorioSelecionado && tipoRelatorioSelecionado.agrupavel" class="d-flex align-center"
               style="gap: 1em">
            <v-switch
                v-model="agrupado"
                inset
                :label="`Deseja agrupar mais de uma opção em um único malote? ${agrupado ? 'Sim' : 'Não'}`"
                class="ml-1"
            >
            </v-switch>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                  <v-icon v-on="on" v-bind="attrs">mdi-information-outline</v-icon>
              </template>
              Uma única página contendo uma lista de opções selecionadas abaixo (limite: 7 opções)
            </v-tooltip>
          </div>
          <v-menu
              v-if="tipoRelatorioSelecionado && precisaPreencherDataProva"
              ref="startMenu"
              :close-on-content-click="false"
              :return-value.sync="dataProva"
              offset-y
              min-width="200px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-form ref="dataProva">
                <v-text-field
                    :rules="[campoObrigatorioRule]"
                    :value="formatDate(dataProva)"
                    label="Data da Prova"
                    prepend-inner-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    outlined
                    style="width: 35%;"
                ></v-text-field>
              </v-form>
            </template>
            <v-card>
              <vc-date-picker
                  color="green"
                  mode="dateTime"
                  is24hr
                  locale="pt-BR"
                  :modelConfig="{ timeAdjust: '12:00:00' }"
                  v-model="dataProva"
                  no-title
                  scrollable
              ></vc-date-picker>
              <v-spacer></v-spacer>
              <v-card-actions class="justify-lg-space-around">
                <v-btn
                    color="error"
                    @click="$refs.startMenu.isActive = false"
                >
                  Cancelar
                </v-btn>
                <v-btn
                    color="primary"
                    @click="
                            $refs.startMenu.save(dataProva)
                          "
                >
                  OK
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-menu>
        </v-col>
      </v-row>
      <v-row v-if="!emBranco || !tipoRelatorioSelecionado.geravelEmBranco">
        <v-card-text v-if="tipoRelatorioSelecionado">
          <div v-if="tipoRelatorioSelecionado.filtraPorCurso"
               :key="processoSelecionado.oid"
          >
            <v-row justify="center">
              <v-alert type="warning" outlined
                       v-if="tipoRelatorioSelecionado.nomeArquivo === 'relatorio_necessidades_especiais'">
                {{
                  opcoesNecessidadeEspecialFiltro.length ? 'Somente as opções abaixo possuem candidatos com necessidades especiais.'
                      : 'Não há opções cujo os candidatos possuam necessidades especiais'
                }}
              </v-alert>
            </v-row>
            <v-row v-if="tipoRelatorioSelecionado.nomeArquivo === 'folha_avaliacao' && opcoesSemAvaliadorQuestao.length"
                   justify="center">
              <v-alert type="warning" outlined text>
                Atenção! Há opções cuja quantidade de questões e/ou quantidade de avaliadores está cadastrada como 0.
              </v-alert>
            </v-row>
            <v-row v-if="opcoesNecessidadeEspecialFiltro.length || opcoes.length">
              <v-col cols="12" class="d-flex justify-space-between">
                <span class="font-weight-bold ml-3 mb-n2 mt-2" style="font-size: 15pt">Opções</span>
                <div>
                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn color="primary" v-on="on" large v-bind="attrs" icon class="mr-1" @click="checkOpcoes">
                        <v-icon>
                          {{
                            opcoesSelecionadas.length ? opcoesSelecionadas.length === (opcoesNecessidadeEspecialFiltro.length - opcoesSemAvaliadorQuestao.length) ? 'mdi-checkbox-outline' : 'mdi-minus-box-outline' : 'mdi-checkbox-blank-outline'
                          }}
                        </v-icon>
                      </v-btn>
                    </template>
                    {{ opcoesSelecionadas.length ? 'Desmarcar todas as opções' : 'Marcar todas as opções' }}
                  </v-tooltip>
                </div>
              </v-col>
              <v-col cols="12">
                <v-card
                    elevation="1"
                >
                  <v-list max-height="400" class="overflow-y-auto" ref="listaOpcoes">
                    <v-list-item-group
                        v-model="opcoesSelecionadas"
                        active-class="primary--text"
                        multiple
                    >
                      <template v-for="(item, index) in opcoesNecessidadeEspecialFiltro">
                        <v-list-item :key="index"
                                     :disabled="(tipoRelatorioSelecionado.nomeArquivo === 'folha_avaliacao' && !item.possuiAvaliadoresEQuestoes) || (!item.possuiCandidatos)">
                          <template v-slot:default="{ active }">
                            <v-list-item-content>
                              <v-list-item-title v-text="item.nomeOpcao"></v-list-item-title>
                              <v-list-item-subtitle>
                                <div v-if="!item.possuiCandidatos">
                                  <span style="color: darkred">Esta opção não possui candidatos</span>
                                </div>
                                <div v-if="tipoRelatorioSelecionado.nomeArquivo === 'folha_avaliacao'">
                                  <span :style="`color: ${item.possuiAvaliadoresEQuestoes ? 'black' : 'darkred'}`">
                                    Avaliadores: {{ item.quantidadeAvaliadores }} - Questões: {{
                                      item.quantidadeQuestoes
                                    }}
                                  </span>
                                </div>
                              </v-list-item-subtitle>
                            </v-list-item-content>
                            <v-list-item-action>
                              <v-icon
                                  v-if="active"
                                  color="yellow darken-3"
                              >
                                mdi-check-circle
                              </v-icon>
                              <v-icon
                                  v-else
                                  color="grey lighten-1"
                              >
                                mdi-checkbox-blank-circle
                              </v-icon>
                            </v-list-item-action>
                          </template>
                        </v-list-item>

                        <v-divider
                            v-if="index < opcoes.length - 1"
                            :key="index.oid"
                        ></v-divider>
                      </template>
                    </v-list-item-group>
                  </v-list>
                </v-card>
              </v-col>
            </v-row>
          </div>
          <div v-if="tipoRelatorioSelecionado.filtraPorLocal"
               :key="processoSelecionado.oid"
          >
            <v-row>
              <v-col cols="12" class="d-flex justify-space-between">
                <span class="font-weight-bold ml-3 mb-n2 mt-2" style="font-size: 15pt">Locais</span>
                <div>
                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn color="primary" v-on="on" large v-bind="attrs" icon class="mr-1" @click="checkLocais">
                        <v-icon>
                          {{
                            locaisSelecionados.length ? locaisSelecionados.length === locaisProva.length ? 'mdi-checkbox-outline' : 'mdi-minus-box-outline' : 'mdi-checkbox-blank-outline'
                          }}
                        </v-icon>
                      </v-btn>
                    </template>
                    {{ locaisSelecionados.length ? 'Desmarcar todos os locais' : 'Marcar todos os locais' }}
                  </v-tooltip>
                </div>
              </v-col>
              <v-col cols="12">
                <v-card
                    elevation="1"
                >
                  <v-list max-height="400" class="overflow-y-auto">
                    <v-list-item-group
                        v-model="locaisSelecionados"
                        active-class="primary--text"
                        multiple
                    >
                      <template v-for="(item, index) in locaisProva">
                        <v-list-item :key="index">
                          <template v-slot:default="{ active }">
                            <v-list-item-content>
                              <v-list-item-title v-text="item.nome"></v-list-item-title>
                            </v-list-item-content>
                            <v-list-item-action>
                              <v-icon
                                  v-if="active"
                                  color="yellow darken-3"
                              >
                                mdi-check-circle
                              </v-icon>
                              <v-icon
                                  v-else
                                  color="grey lighten-1"
                              >
                                mdi-checkbox-blank-circle
                              </v-icon>
                            </v-list-item-action>
                          </template>
                        </v-list-item>

                        <v-divider
                            v-if="index < locaisProva.length - 1"
                            :key="index.oid"
                        ></v-divider>
                      </template>
                    </v-list-item-group>
                  </v-list>
                </v-card>
              </v-col>
            </v-row>
          </div>
        </v-card-text>
      </v-row>
    </v-card-text>
    <v-card-actions class="justify-center">
      <v-spacer/>
      <v-btn width="100" color="primary" @click="gerarRelatorio" :disabled="!tipoRelatorioSelecionado" class="mx-2">
        Gerar
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import processoService from "@/services/processo.service";
import materialAplicacaoService from "@/services/materialAplicacao.service";
import localProvaService from "@/services/localProva.service";
import opcaoService from "@/services/opcao.service";
import luxon from "@/core/luxon";
import rules from "@/commons/rules";
import {mapActions} from "vuex";

export default {
  name: "MaterialAplicacao",
  components: {},


  data() {
    return {
      processos: [],
      agrupado: false,
      tiposRelatorio: [],
      processoSelecionado: null,
      loaded: false,
      loadTiposRelatorios: false,
      tipoRelatorioSelecionado: null,
      opcoes: [],
      opcoesSelecionadas: [],
      locaisProva: [],
      locaisSelecionados: [],
      emBranco: false,
      dataProva: null,
      simOuNao: [
        {value: true, text: "Sim"},
        {value: false, text: "Não"}
      ]
    }
  },

  async created() {
    let loader = this.$loading.show();

    await processoService.recuperarProcessosMaterialAplicacao().then(response => {
      this.processos = response.data;
    });

    this.loaded = true;
    loader.hide();
  },

  computed: {
    opcoesNecessidadeEspecialFiltro() {
      if (this.tipoRelatorioSelecionado.nomeArquivo === "relatorio_necessidades_especiais") {
        return this.opcoes.filter(o => o.possuiCandidatoNecessidadeEspecial);
      }
      return this.opcoes;
    },

    opcoesSemAvaliadorQuestao() {
      if (this.tipoRelatorioSelecionado.nomeArquivo === "folha_avaliacao") {
        return this.opcoes.filter(o => !o.possuiAvaliadoresEQuestoes);
      }
      return [];
    },
    tiposRelatorioDisponiveis() {
      return this.tiposRelatorio.filter(t => t.materialAplicacao);
    },
    possuiProvaPresencial() {
      if (this.processoSelecionado) {
        return this.processoSelecionado.especificacoes.filter(e => e.codigo === 'PRES').length
      }
      return false
    },
    possuiAlocacao() {
      if (this.processoSelecionado) {
        return this.processoSelecionado.especificacoes.filter(e => e.codigo === 'ALC').length
      }
      return false
    },
    precisaPreencherDataProva() {
      if (this.processoSelecionado && this.tipoRelatorioSelecionado) {
        return this.tipoRelatorioSelecionado.possuiDataProva && this.possuiProvaPresencial && !this.possuiAlocacao
      }
      return false
    }
  },

  watch: {
    processoSelecionado: {
      async handler() {
        if (this.processoSelecionado) {
          this.opcoes = [];
          this.locaisProva = [];
          this.opcoesSelecionadas = [];
          this.locaisSelecionados = [];
          await materialAplicacaoService.recuperarTiposRelatoriosDisponiveis(this.processoSelecionado.oid).then(response => {
            this.tiposRelatorio = response.data;
          });
          await localProvaService.recuperarLocaisProvaPorProcesso(this.processoSelecionado.oid).then(response => {
            this.locaisProva = response.data;
          });
          await opcaoService.recuperarOpcoesRelatorioPorProcesso(this.processoSelecionado.oid).then(response => {
            this.opcoes = response.data;
          });
          this.loadTiposRelatorios = true;
        }
      },
      deep: true
    },

    tipoRelatorioSelecionado: {
      handler() {
        if (this.tipoRelatorioSelecionado) {
          this.locaisSelecionados = [];
          this.opcoesSelecionadas = [];
        }
      },
      deep: true
    }
  },

  methods: {
    ...rules,
    ...mapActions("avisos", ["exibirAviso"]),
    async gerarRelatorio() {
      if (this.agrupado && this.opcoesSelecionadas.length > 7) {
        this.exibirAviso({
          mensagem: "Não é possível agrupar mais de 7 opções em um malote",
          tipo: "error"
        });
      } else {
      if (!this.precisaPreencherDataProva || (this.precisaPreencherDataProva && this.$refs.dataProva.validate())) {
        let loader = this.$loading.show();

        let relatorioRequest = {
          processoOid: this.processoSelecionado.oid,
          opcoes: this.opcoes.filter((o, index) => this.opcoesSelecionadas.includes(index)),
          locais: this.locaisProva.filter((l, index) => this.locaisSelecionados.includes(index)),
          tipoRelatorio: this.tipoRelatorioSelecionado,
          emBranco: this.emBranco,
          agrupado: this.agrupado,
          dataProva: this.dataProva ? this.dataConverter(this.dataProva) : null
        }

        if (this.tipoRelatorioSelecionado.geraRelatorioPdf) {
          await materialAplicacaoService.gerarRelatorioPDF(relatorioRequest).then(response => {
            this.downloadRelatorio("application/pdf", ".pdf", response.data);
          });
        } else {
          await materialAplicacaoService.gerarRelatorioCSV(relatorioRequest).then(response => {
            this.downloadRelatorio("text/csv", ".csv", response.data);
          });
        }

        loader.hide();
      }
      }

    },

    formatDate(data) {
      if (data === null || data === undefined) {
        return "";
      }
      return luxon.dataConverter(data).toFormat("dd/LL/yyyy HH:mm");
    },

    dataConverter(data) {
      return luxon.dataConverter(data).toFormat("yyyy-LL-dd TT");
    },

    downloadRelatorio(tipo, extensao, arquivo) {
      const file = new Blob([arquivo], {type: tipo});
      const fileURL = URL.createObjectURL(file);
      let fileLink = document.createElement("a");
      fileLink.href = fileURL;
      fileLink.download = this.tipoRelatorioSelecionado.nomeArquivo + '_' + this.processoSelecionado.nome.replace(/\s/g, '_').toLowerCase() + extensao;
      fileLink.click();
    },

    checkOpcoes() {
      if (!this.opcoesSelecionadas.length) {
        if (this.tipoRelatorioSelecionado.nomeArquivo === 'folha_avaliacao') {
          this.opcoes.forEach((i, index) => {
            if (!this.opcoesSemAvaliadorQuestao.includes(i)) {
              this.opcoesSelecionadas.push(index)
            }
          });
        } else {
          this.opcoes.forEach((i, index) => {
            if (i.possuiCandidatos) {
              this.opcoesSelecionadas.push(index)
            }
          });
        }
      } else {
        this.desmarcarTodos();
      }
    },

    checkLocais() {
      if (!this.locaisSelecionados.length) {
        this.locaisProva.forEach((l, index) => this.locaisSelecionados.push(index));
      } else {
        this.desmarcarTodos();
      }
    },

    desmarcarTodos() {
      this.locaisSelecionados = [];
      this.opcoesSelecionadas = [];
    }
  }
}
</script>

<style scoped>

</style>