import Vue from "vue";
import luxon from "@/core/luxon";

export default {
    recuperarFiltro(paramsFiltro, pagina, numElementosPagina, ordenacao) {
        return Vue.axios.post("localProva/filtro", {
            params: {
                processoOid: paramsFiltro.processoOid
            },
            pagina,
            numElementosPagina,
            ordenacao
        });
    },

    recuperarLocaisProvaPorProcesso(processoOid) {
        return Vue.axios.get("localProva/processo/" + processoOid);
    },

    criarLocalProva(localProva, processoOid) {
        localProva = {
            ...localProva,
            processo: {oid: processoOid}
        }

        localProva.dataHora = this.dataConverter(localProva.dataHora);

        return Vue.axios.post("localProva", localProva);
    },

    atualizarLocalProva(localProva) {
        localProva.dataHora = this.dataConverter(localProva.dataHora);

        return Vue.axios.put("localProva", localProva);
    },

    importarLocaisProva(locaisProva, processoOid) {
        return Vue.axios.post("localProva/importarLocais/" + processoOid, locaisProva);

    },

    dataConverter(data) {
        return luxon.dataConverter(data).toFormat("yyyy-LL-dd TT");
    },

    getLocalProvaByOid(localProvaOid) {
        return Vue.axios.get("localProva/" + localProvaOid)
    },

    excluirLocalProva(localProvaOid) {
        return Vue.axios.delete("localProva/excluir/" + localProvaOid);
    }
};